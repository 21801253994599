import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import Calendly from '../components/Bookings/Calendly'

const Services = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Let's collaborate." 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Schedule a meeting to get started" 
            />
            <Calendly />
            <Footer />
        </Layout>
    );
}

export default Services