import React from 'react'
import { InlineWidget } from "react-calendly";

const Calendly = () => {
    const calendar = `${process.env.CALENDLY_URL}`
    return (
        <section className="solutions-area pt-100 pb-70" style={{ padding : 0 , backgroundColor: "#ffffff"}}>
            <div className="container">
                <div className="row">
                    <div className="App">
                        <InlineWidget 
                            url={calendar}
                            styles={{
                                height: '1000px'
                            }}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: '00a2ff',
                                textColor: '4d5055'
                            }}
                        />
                    
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Calendly;